/* REACT */
import { useCallback, useEffect, useState } from "react";

/* SERVICES */
import ApiService from "../Services/ApiService";
import { IAPIResponse } from "../Services/Internal/AjaxService";

/* UTILITY */
import { ToastMessage } from "../Utils/Toastify";

/* MODELS */
import { IADUsers } from "../Models/IActiveDirectory";

/* HOOK */
const useADUsers = () => {
  /* STATE */
  const [ADUsers, setADUsers] = useState<any[]>([]);
  // Loading
  const [loadingADUsers, setLoadingADUsers] = useState(true);

  /* ACTIVE DIRECTORY USERS */
  const getADUsers = useCallback(() => {
    ApiService.ADController.getADUsers((response: IAPIResponse) => {
      setLoadingADUsers(false);

      if (response.error === null) {
        const usersFiltered = response.payload.value.filter(
          (item: IADUsers) => item.mail !== null
        );

        const users = usersFiltered.map((item: IADUsers) => ({
          key: item.mail,
          text: item.mail,
        }));

        setADUsers(users);
      } else {
        ToastMessage("Error.", "error");
      }
    });
  }, []);

  /* RENDER USERS */
  useEffect(() => {
    getADUsers();
  }, [getADUsers]);

  /* RETURN */
  return { ADUsers, loadingADUsers };
};

export default useADUsers;
