/* STYLE */
import "./PageHome.scss";

/* COMPONENT */
const PageHome = () => {
  /* RETURN */
  return (
    <div className="page-base-lvl-0 fadeIn">
      <div className="Home__container">
        <h2>Welcome in</h2>

        <h1>Teams Demo</h1>
      </div>
    </div>
  );
};

export default PageHome;
