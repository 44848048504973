/* REACT */

/* STYLE */
import "./DataGrid.scss";

/* SERVICES */

/* UTILITY */

/* MODELS */
import { IGetTask } from "../../Models/ITask";

/* COMPONENTS */

/* MUI */
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { Stack } from "@mui/material";
import { DataGrid, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* INTERFACE */
export interface IDataGrid {
  dataList: any[];
}

/* COMPONENT */
const DataGridTable = (props: IDataGrid) => {
  /* CHIP STATUS */
  const renderStatus = ({ value }: GridRenderCellParams) => {
    const statusMap: any = {
      null: { label: "Undefined", color: "warning" },
      Approved: { label: "Approved", color: "success" },
      Assigned: { label: "Assigned", color: "primary" },
      Deleted: { label: "Delete", color: "error" },
      Rejected: { label: "Reject", color: "error" },
    };

    const status: any = statusMap[value] || {
      label: "Undefined",
      color: "warning",
    };

    return <Chip label={status.label} color={status.color} />;
  };

  /* RETURN */
  return (
    <>
      <Accordion elevation={3}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6">Items List</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <DataGrid
            sx={{ padding: "0.5em", minHeight: "300px" }}
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No data
                </Stack>
              ),
              noResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No result
                </Stack>
              ),
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            columns={[
              { field: "Title", flex: 2 },
              { field: "Status", flex: 1, renderCell: renderStatus },
            ]}
            rows={props.dataList.map((item: IGetTask) => ({
              id: item.TaskId,
              TaskId: item.TaskId,
              Title: item.TaskTitle,
              Status: item.TaskStatus,
              TaskData: item,
            }))}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DataGridTable;
