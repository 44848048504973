/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* STYLE */
import "./PageLogin.scss";

/* UTILITY */
import { ToastMessage } from "../../Utils/Toastify";
import { useDispatch, useSelector } from "react-redux";

/* COMPONENTS */
import ParticlesWrapper from "../../components/Particles/ParticlesWrapper";
import MultiForm from "../../components/MultiForm/MultiForm";
import LoaderBackdrop from "../../components/LoaderBackdrop/LoaderBackdrop";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { AppRoutes } from "../../AppRoutes";

/* COMPONENT */
const PageLogin = () => {
  /* STATE */
  // Loading
  const [loadingLogin, setLoadingLogin] = useState(false);

  const dispatch = useDispatch();

  /* NAVIGATE */
  const navigate = useNavigate();

  /* LOGIN */
  const delegatedLogin = (entityIdentifier: string, secret: string) => {
    setLoadingLogin(true);
    ApiService.SessionController.delegatedLogin(
      entityIdentifier,
      secret,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(
            (GenericActions as any).setMTAToken(
              response.payload.Token as string
            )
          );
          navigate(AppRoutes.HOME_ROUTE);
        } else {
          ToastMessage(
            "Error during delegated login. Check credentials.",
            "error"
          );
        }

        setLoadingLogin(false);
      }
    );
  };

  /* CLEAN DATA */
  const emptyUserData = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    emptyUserData();
  }, []);

  /* RETURN  */
  return (
    <>
      <ParticlesWrapper />

      <div className="page-intro-lvl-0">
        <div className="page-intro-lvl-1">
          <div className="page-intro-logo">
            <div className="page-intro-title">Teams Demo</div>

            <img
              className="page-navigator-nav-logo"
              src="/assets/logo-demo.svg"
              alt="Logo Demo"
            />
          </div>
          <div className="page-intro-content">
            <div className="row">
              <div className="page-intro-explain-box col-md-12">
                <MultiForm
                  suppressLayout
                  formId="form-page-tasks"
                  submitButtonLabel="Sign in"
                  inputs={[
                    {
                      width: 100,
                      type: "text",
                      name: "user",
                      label: "User",
                      defaultValue: "",
                      required: true,
                    },
                    {
                      width: 100,
                      type: "password",
                      name: "password",
                      label: "Password",
                      defaultValue: "",
                      required: true,
                    },
                  ]}
                  onSubmit={(data: any) => {
                    delegatedLogin(data.user, data.password);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoaderBackdrop loading={loadingLogin} />
    </>
  );
};

export default PageLogin;
