import { useEffect, useState } from "react";
import HeaderPage from "./components/HeaderPage/HeaderPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AppRoutes, AppRoutesMap, IAppRoute } from "./AppRoutes";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "./Redux/RootReducer";
import PageNavigator from "./Pages/PageNavigator/PageNavigator";
import ApiService from "./Services/ApiService";
import { IAPIResponse } from "./Services/Internal/AjaxService";
import { GenericActions } from "./Redux/Generic/GenericAction";

/* COMPONENT */
const AppLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token: string | undefined = useSelector(
    (state: GlobalState) => state.generic.myTeamsAssistantToken
  );
  const [routes, setRoutes] = useState<IAppRoute[]>([]);

  /* APP DICTIONARIES */
  const getAppDictionaries = () => {
    ApiService.SessionController.appDictionaries((response: IAPIResponse) => {
      if (response.error === null) {
        dispatch((GenericActions as any).setAppDictionaries(response.payload));
      }
    });
  };

  const getSelfInfos = () => {
    ApiService.SessionController.getSelfInfos((response: IAPIResponse) => {
      if (response.error === null) {
        dispatch((GenericActions as any).setUser(response.payload));
      }
    });
  };

  useEffect(() => {
    setRoutes(
      AppRoutesMap.filter(
        (x: IAppRoute) => !x.requresAuth || token !== undefined
      )
    );

    if (token) {
      getSelfInfos();

      if (window.location.pathname.includes(AppRoutes.LOGIN_ROUTE)) {
        navigate(AppRoutes.HOME_ROUTE);
      }

      getAppDictionaries();
    }
  }, [token]);

  /* RETURN */
  return (
    <div className="flex-container">
      {token && <PageNavigator />}

      <Routes>
        {routes.map((item: IAppRoute, i: number) => (
          <Route
            key={i}
            path={item.path}
            element={
              <div className="full-width">
                {token && <HeaderPage />}

                {item.element}
              </div>
            }
          />
        ))}
      </Routes>
    </div>
  );
};

export default AppLayout;
