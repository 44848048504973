// Per tornare a localhost - Ripristinare riga 110 - 114 e 359 togliere logica FALSE

import { TryGetApiFromCache, AddApiInCache } from "./ApiCacheSubSystem";

export interface IAPIRequest {
  url: string;
  port?: string | "7006";
  method: string | null;
  payload: any | null;
  extraHeaders: any | null;
  ignoreDefaultHeaders: boolean | null;
  successMessage: string | null;
  silentLevel: number;
  dontStringify: boolean | null;
  spinnerLabel: string | null;
  skipResponseJsonParse: boolean;
  alternative_base_url: string | null;
}

export interface IAPIResponse {
  error: string | null;
  payload: any | null;
  raw: any | null;
}

export interface ICustomBehaviorCallbacks {
  getSessionToken: Function;
  preRequestScript: Function;
  errorDetectionFunction: Function;
  payloadOutFormat: Function;
  payloadInFormat: Function;
}

export interface IAPISettings {
  generalErrorText: string;
  spinnerColor: string;
  baseUrl: string;
  basePort: string;
  spinnerGlobalStyle: string;
  defaultSpinnerLabel: string;
  maxToastMessageDuration: number;
  autoLogoutTest: Function;
  defaultApiParams: any;
  defaultHeaders: any;
  requiredHeaders: () => any;
  customCallBacks: ICustomBehaviorCallbacks;
  developerMode: boolean;
}

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Generate request utility function
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */

export const silentLevels = {
  NO_UI_INTERACTIONS: 0,
  ALLOW_TOASTS: 1,
};

export const createEmptyRequest = () => {
  let defaultRequest: IAPIRequest = {
    url: "",
    method: "get",
    payload: null,
    extraHeaders: null,
    ignoreDefaultHeaders: false,
    successMessage: null,
    silentLevel: silentLevels.ALLOW_TOASTS,
    dontStringify: false,
    spinnerLabel: null,
    skipResponseJsonParse: false,
    alternative_base_url: null,
  };

  return defaultRequest;
};

const autoLoginCallback = async () => {
  (window as any)["goToLogin"]();
};

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Generic settings:
 * The following code holds generic settings needed to setup the API system.
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */
// some basic settings

var SUPPRESS_ALL_ERROR_TOASTS = true;

export const getBaseUrl = () => {
  return process.env.REACT_APP_BE_BASE_URL;
};

export const APISettings: IAPISettings = {
  developerMode: window.location.href.indexOf("localhost") !== -1,
  generalErrorText: "C'è stato un problema. Per favore, riprovate più tardi.",
  spinnerColor: "#FFD300",
  baseUrl: "",
  basePort: "7006",
  spinnerGlobalStyle: "solid-dual",
  defaultSpinnerLabel: "Loading",
  maxToastMessageDuration: 5000,
  autoLogoutTest: (apiResponse: IAPIResponse) => {
    if (apiResponse.raw.status === 401) {
      SUPPRESS_ALL_ERROR_TOASTS = true;
      (window as any)["deny_all_messages"] = true;
      autoLoginCallback();
    }
  },
  defaultApiParams: {
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: "include", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  },
  requiredHeaders: () => {
    let headers: any = {};
    let tokenH = localStorage.getItem("myteams-assistant-sessionToken");
    if (tokenH) {
      headers["Authorization"] = "Bearer " + tokenH;
    } else {
      console.warn("Token was requested by API but is missing in storage");
    }
    headers["Content-Language"] = localStorage.getItem("language");
    return headers;
  },
  defaultHeaders: {},
  customCallBacks: {
    getSessionToken: () => {
      return null;
    },
    preRequestScript: (request: IAPIRequest) => {},
    errorDetectionFunction: (response: IAPIResponse) => {
      if (response.raw.status === 500) {
        return APISettings.generalErrorText;
      }
      if (response.payload && response.payload.detail) {
        return response.payload.detail;
      }
      return null;
    },
    payloadOutFormat: (payload: any) => {
      return payload;
    },
    payloadInFormat: (payload: any) => {
      return payload;
    },
  },
};

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Core code:
 * The following code should not be edited and is valid in general for any project.
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */

// exported methods
export const AjaxService = {
  call, // call Api inside controlled api flow
  downloadBase64File, // download file
  downloadFileFromUrl, // download from link
  openToastSuccess, // open toast: green
  openToastWarning, // open toast: yellow
  openToastError, // open toast: red
  getPopUp, // open a popup
  getToast, // open a small toast (similar to an android toast)
};

function downloadFileFromUrl(url: string) {
  let link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
}

function downloadBase64File(fileName: string, base64: string) {
  let type: string | null = null;
  let splName = fileName.split(".");
  let extension = "." + splName[splName.length - 1].toLowerCase();

  let contentTypes = [
    { ext: ".doc", cType: "application/msword" },
    { ext: ".dot ", cType: "application/msword" },
    {
      ext: ".docx",
      cType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      ext: ".dotx",
      cType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    },
    { ext: ".docm", cType: "application/vnd.ms-word.document.macroEnabled.12" },
    { ext: ".dotm", cType: "application/vnd.ms-word.template.macroEnabled.12" },
    { ext: ".xls ", cType: "application/vnd.ms-excel" },
    { ext: ".xlt ", cType: "application/vnd.ms-excel" },
    { ext: ".xla ", cType: "application/vnd.ms-excel" },
    {
      ext: ".xlsx",
      cType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      ext: ".xltx",
      cType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    },
    { ext: ".xlsm", cType: "application/vnd.ms-excel.sheet.macroEnabled.12" },
    {
      ext: ".xltm",
      cType: "application/vnd.ms-excel.template.macroEnabled.12",
    },
    { ext: ".xlam", cType: "application/vnd.ms-excel.addin.macroEnabled.12" },
    {
      ext: ".xlsb",
      cType: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    },
    { ext: ".ppt ", cType: "application/vnd.ms-powerpoint" },
    { ext: ".pot ", cType: "application/vnd.ms-powerpoint" },
    { ext: ".pps ", cType: "application/vnd.ms-powerpoint" },
    { ext: ".ppa ", cType: "application/vnd.ms-powerpoint" },
    {
      ext: ".pptx",
      cType:
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    },
    {
      ext: ".potx",
      cType:
        "application/vnd.openxmlformats-officedocument.presentationml.template",
    },
    {
      ext: ".ppsx",
      cType:
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    },
    {
      ext: ".ppam",
      cType: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    },
    {
      ext: ".pptm",
      cType: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    },
    {
      ext: ".potm",
      cType: "application/vnd.ms-powerpoint.template.macroEnabled.12",
    },
    {
      ext: ".ppsm",
      cType: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    },
    { ext: ".mdb ", cType: "application/vnd.ms-access" },
    { ext: ".pdf ", cType: "application/pdf" },
    { ext: ".csv ", cType: "text/csv" },
    { ext: ".mp4", cType: "video/mp4" },
    { ext: ".ogg", cType: "video/ogg" },
    { ext: ".webm", cType: "video/webm" },
    { ext: ".png", cType: "image/png" },
    { ext: ".bmp", cType: "image/bmp" },
    { ext: ".jpg", cType: "image/jpg" },
  ];

  for (let i = 0; i < contentTypes.length; i++) {
    let t = contentTypes[i];
    if (t.ext === extension) {
      type = t.cType;
      break;
    }
  }

  if (type == null) {
    type = "application/octet-stream";
  }

  let base64ToArrayBuffer = function (base64: string) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  let saveByteArray = function (
    fileName: string,
    byte: Uint8Array,
    type: string
  ) {
    let blob = new Blob([byte], { type: type });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  let byteArray = base64ToArrayBuffer(base64);
  saveByteArray(fileName, byteArray, type);
}

async function call(apiRequest: IAPIRequest) {
  // is a post, so try get the AF Token
  // if (apiRequest.method!.toLocaleLowerCase() === "post") {
  //   let AFRequest: IAPIRequest = createEmptyRequest();
  //   AFRequest.url = "/AntiForgery/getToken";
  //   let response: IAPIResponse = await callInner(AFRequest);
  //   let AFToken = null;
  //   if (response.error === null) {
  //     AFToken = response.payload.token;
  //   }

  //   // add the token to the request as header
  //   if (AFToken) {
  //     if (!apiRequest.extraHeaders) {
  //       apiRequest.extraHeaders = {};
  //     }
  //     apiRequest.extraHeaders["af_token"] = AFToken;
  //   }
  // }

  return await callInner(apiRequest);
}

async function callInner(apiRequest: IAPIRequest) {
  if (APISettings.customCallBacks.preRequestScript(apiRequest)) {
    return {
      raw: null,
      payload: null,
      error:
        "Pre request script blocked this call: " + JSON.stringify(apiRequest),
    };
  }
  let basePort = APISettings.basePort;
  if (apiRequest.port) {
    basePort = apiRequest.port;
  }

  // no custom ports on cloud
  if (window.location.origin.includes("localhost") && false) {
    basePort = ":" + basePort;
  } else {
    basePort = "";
  }

  /** custom format payload (if necessary) */
  apiRequest.payload = APISettings.customCallBacks.payloadOutFormat(
    apiRequest.payload
  );

  let response = await doFetch(apiRequest);

  if (response != null) {
    if (response.raw.status >= 300 || response.raw.status < 200) {
      response["error"] = extractError(response);
    } else {
      // no custom error extractor
      response["error"] = null;
    }

    if (apiRequest.silentLevel !== silentLevels.NO_UI_INTERACTIONS) {
      if (response["error"] != null) {
        if (!SUPPRESS_ALL_ERROR_TOASTS) {
          openToastError(response["error"]);
        } else {
          console.error(response["error"]);
        }
      }

      if (response["error"] == null && apiRequest.successMessage) {
        openToastSuccess(apiRequest.successMessage);
      }
    }
  }

  return response;
}

async function doFetch(apiRequest: IAPIRequest) {
  let cachedResult: IAPIResponse | null = TryGetApiFromCache(apiRequest);
  // try get any session token (jwt, b2c, etc... )
  let token = APISettings.customCallBacks.getSessionToken();

  // Default params for fetch call
  const params = JSON.parse(JSON.stringify(APISettings.defaultApiParams));

  if (cachedResult) {
    return cachedResult;
  }

  // set method (get is default )
  params["method"] = apiRequest.method ? apiRequest.method : "get";

  // add custom headers
  params["headers"] = obtainHeaders(apiRequest);

  if (token != null) {
    params["headers"]["Authorization"] = "Bearer " + token;
  }

  if (apiRequest.payload != null) {
    if (apiRequest.dontStringify === true) {
      params["body"] = apiRequest.payload;
    } else {
      params["body"] = JSON.stringify(apiRequest.payload); // body data type must match "Content-Type" header
    }
  }

  let response: any = null;

  try {
    let baseUrl_: any = getBaseUrl();
    if (process.env.REACT_APP_BE_BASE_URL?.includes("localhost")) {
      baseUrl_ =
        baseUrl_ +
        ":" +
        (apiRequest.port !== undefined
          ? apiRequest.port
          : APISettings.basePort);
    }
    response = await fetch(baseUrl_ + apiRequest.url, params);
  } catch (e: any) {
    response = { status: -1, message: e.message };
  }

  let value: any = null;
  if (!apiRequest.skipResponseJsonParse) {
    try {
      value = await response.json();
    } catch (e: any) {
      console.error("Could not parse response", e.message);
    }
  } else {
    value = response;
  }

  let output: IAPIResponse = {
    raw: response,
    payload: value,
    error: null,
  };

  APISettings.autoLogoutTest(output);

  output.payload = APISettings.customCallBacks.payloadInFormat(output.payload);

  // try cache this for later reuse
  AddApiInCache(apiRequest, output);

  return output;
}

function obtainHeaders(apiRequest: IAPIRequest) {
  let headers = { ...APISettings.defaultHeaders };

  if (apiRequest.ignoreDefaultHeaders === true) {
    headers = {};
  }

  //** required headers cannot be removed */
  headers = { ...headers, ...APISettings.requiredHeaders() };

  if (apiRequest.extraHeaders != null) {
    let keys = Object.keys(apiRequest.extraHeaders);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (apiRequest.extraHeaders.hasOwnProperty(key)) {
        headers[key] = apiRequest.extraHeaders[key];
      }
    }
  }

  return headers;
}

function extractError(response: IAPIResponse) {
  /** first attempt to get the error is taken by the custom error */
  let error = APISettings.customCallBacks.errorDetectionFunction(response);

  /** second attempt from the standard http error statusText voice */
  if (response.raw && response.raw.statusText && error == null) {
    error = response.raw.statusText;
  }

  /** third attempt from the standard http error message voice */
  if (response.raw && response.raw.message && error == null) {
    error = response.raw.message;
  }

  return error ?? APISettings.generalErrorText;
}

function openToastSuccess(message: string) {
  openToast(message, "#a5d45a");
}
function openToastWarning(message: string) {
  openToast(message, "#ded34c", -1, true);
}

var errorToastDone: boolean = false;
function openToastError(message: string) {
  if (errorToastDone) {
    return;
  }
  errorToastDone = true;
  openToast(message, "#e82727", -1, true);
  setTimeout(() => {
    errorToastDone = false;
  }, 200);
}

const openToast = (
  message: string,
  color: string,
  forceDuration?: number | -1,
  keepOpen?: boolean | false
) => {
  let randId = "_" + Math.random().toString(36).substr(2, 9);

  let holder: any = document.getElementById("custom-toast-holder");
  if (holder == null) {
    holder = document.createElement("div");
    holder.id = "custom-toast-holder";

    holder.style.position = "fixed";
    holder.style.zIndex = "10001";
    holder.style.alignItems = "center";
    holder.style.top = "1em";
    holder.style.right = "2em";

    document.body.appendChild(holder);
  }

  if (holder.children !== null && holder.children.length > 5) {
    holder.removeChild(holder.firstChild);
  }

  let t = document.createElement("div");

  t.id = randId;

  t.className = "custom-toast";
  t.style.maxHeight = "15em";
  t.style.userSelect = "none";
  t.style.overflow = "hidden";
  t.style.transition = "all 0.5s ease-out";
  t.style.padding = "0.5em";
  t.style.borderRadius = "0.5em";
  t.style.textAlign = "center";
  t.style.margin = "0.5em auto";
  t.style.right = "-35em";
  t.style.position = "relative";
  t.style.background = color;
  t.style.minWidth = "min(18em, 40vw)";
  t.style.maxWidth = "30em";
  t.style.boxShadow = "0.2em 0.2em 0.2em rgba(0,0,0,0.3)";
  t.style.color = "#FFF";
  t.style.textShadow =
    "rgb(0 0 0 / 20%) 1px 0px 0px, rgb(0 0 0 / 20%) 0px -1px 0px, rgb(0 0 0 / 20%) 0px 1px 0px, rgb(0 0 0 / 20%) -1px 0px 0px";
  t.style.fontWeight = "bold";

  let cls = document.createElement("div");
  cls.innerHTML = "X";
  cls.style.float = "right";
  cls.style.cursor = "pointer";
  cls.style.marginTop = "-0.4em";
  cls.style.transform = "scaleX(1.3)";

  cls.setAttribute(
    "onclick",
    "let w = this.parentNode; w.style.right = '-35em';w.style.maxHeight = '0';w.style.padding = '0';w.style.margin = '0';"
  );

  let rows = message.split(";").length;

  let msg = document.createElement("div");
  msg.innerHTML = message.split(";").join("<br />");
  msg.style.fontSize = "1.1em";
  msg.style.minHeight = "3em";
  msg.style.display = "flex";
  msg.style.alignItems = "center";
  msg.style.justifyContent = "center";

  t.appendChild(cls);
  t.appendChild(msg);

  holder.appendChild(t);
  setTimeout(() => {
    t.style.right = "0";
  }, 50);

  let duration =
    1000 + 400 * rows + (message.length <= 50 ? message.length : 50) * 80;

  if (duration > 3000) {
    duration = 3000;
  }

  if (forceDuration && forceDuration > 0) {
    duration = forceDuration;
  }

  if (!keepOpen) {
    setTimeout(() => {
      t.style.right = "-35em";
    }, duration);

    setTimeout(() => {
      t.style.maxHeight = "0";
      t.style.padding = "0";
      t.style.margin = "0";
    }, duration + 100);

    setTimeout(() => {
      let toast = document.getElementById(randId);
      if (toast !== null && toast.parentNode !== null) {
        toast.parentNode.removeChild(toast);
      }
    }, duration + 1000);
  }
};

function getToast(message: string) {
  let w = document.createElement("div");

  let toast: any = document.createElement("div");
  toast.style.background = "black";
  toast.style.opacity = 0;
  toast.style.padding = "1em 3em";
  toast.style.borderRadius = "1em";
  toast.style.fontSize = "min(2vw,1em)";
  toast.style.margin = "0 auto";
  toast.style.position = "fixed";
  toast.style.color = "white";
  toast.style.top = "2em";
  toast.style.transition = "opacity 0.5s ease-out";
  toast.style.zIndex = "1000";

  toast.innerHTML = message;

  w.id = "toast-element-wrap";
  w.style.width = "100%";
  w.style.display = "flex";
  w.style.flexDirection = "column";
  w.style.alignItems = "center";

  w.appendChild(toast);

  document.body.appendChild(w);

  let life = message.length * 60 + 2000;

  setTimeout(() => {
    toast.style.opacity = 1;
  }, 50);

  setTimeout(() => {
    toast.style.opacity = 0;
  }, life);

  setTimeout(() => {
    let e = document.getElementById("toast-element-wrap");
    if (e != null) {
      document.body.removeChild(e);
    }
  }, life + 1000);
}

function getPopUp(titleText: string, innerHtml: any) {
  let inkdrop: any = document.createElement("div");
  let popup: any = document.createElement("div");
  let header: any = document.createElement("div");
  let title: any = document.createElement("div");
  let exit: any = document.createElement("div");
  let content: any = document.createElement("div");

  inkdrop.id = "popup-inkdrop-base";
  inkdrop.style.position = "fixed";
  inkdrop.style.top = 0;
  inkdrop.style.left = 0;
  inkdrop.style.width = "100vw";
  inkdrop.style.height = "100vh";
  inkdrop.style.display = "flex";
  inkdrop.style.alignItems = "center";
  inkdrop.style.justifyContent = "space-around";
  inkdrop.style.zIndex = 3000;
  inkdrop.style.background = "rgba(0,0,0,0.7)";

  popup.style.fontSize = "min(2vw, 1em)";
  popup.style.width = "90%";
  popup.style.border = "1px solid black";
  popup.style.padding = "1em";
  popup.style.background = "white";
  popup.style.borderRadius = "1em";

  header.style.borderBottom = "1px solid rgba(0,0,0,0.2)";
  header.style.marginBottom = "1em";
  header.style.display = "flex";
  header.style.justifyContent = "space-between";

  title.style.fontWeight = "bold";

  exit.style.fontSize = "min(4vw,1.5em)";
  exit.style.fontFamily = "inherit";
  exit.style.lineHeight = "0.7em";
  exit.style.border = "1px solid rgba(0,0,0,0.3)";
  exit.style.borderRadius = "50%";
  exit.style.textAlign = "center";
  exit.style.width = "1em";
  exit.style.height = "1em";
  exit.style.textAlign = "1em";
  exit.style.cursor = "pointer";
  exit.style.position = "relative";
  exit.style.top = "-0.2em";

  content.style.overflowY = "auto";
  content.style.maxHeight = "65vh";
  content.style.padding = "1em";
  content.style.border = "1px solid rgba(0,0,0,0.2)";
  content.style.whiteSpace = "pre-wrap";

  title.innerHTML = titleText;

  exit.innerHTML = "x";
  exit.onclick = function () {
    let i: any = document.getElementById("popup-inkdrop-base");
    document.body.removeChild(i);
  };

  content.innerHTML = innerHtml;

  inkdrop.appendChild(popup);
  popup.appendChild(header);
  header.appendChild(title);
  header.appendChild(exit);
  popup.appendChild(content);

  document.body.appendChild(inkdrop);
}
