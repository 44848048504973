import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  SliderThumb,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { SummonModal } from "../SmartModal/SmartModal";
import "./MultiForm.scss";
import BetterSelect from "./SpecialInputs/BetterSelect";
import BetterSelectMultiple from "./SpecialInputs/BetterSelectMultiple";
import PasswordTextField from "./SpecialInputs/PasswordTextFiels";
import StrongDatePicker from "./SpecialInputs/StrongDatePicker";
import StrongDateTimePicker from "./SpecialInputs/StrongDateTimePicker";
import StrongTimePicker from "./SpecialInputs/StrongTimePicker";

export interface IMultiFormField {
  name: string;
  ignore?: boolean;
  type:
    | "text"
    | "email"
    | "multiline"
    | "number"
    | "decimal"
    | "integer"
    | "tel"
    | "password"
    | "select"
    | "checkbox"
    | "switch"
    | "date"
    | "url"
    | "time"
    | "custom"
    | "datetime"
    | "file"
    | "select_nations"
    | "radio"
    | "autocomplete"
    | "hidden"
    | "slider"
    | "select_multiple";
  required?: boolean;
  defaultValue?: any;
  currentValue?: any;
  options?: { key: string | number | boolean; text: string }[];
  errorText?: string;
  hint?: string;
  min?: number;
  max?: number;
  variant?: "outlined" | "standard" | "filled";
  label?: string;
  placeholder?: string;
  upperLabel?: string;
  disabled?: boolean;
  width?: number;
  element?: JSX.Element;
  inputProps?: any;
  inputAdornament?: any;
  className?: string;
  fullWidth?: boolean | false;
  multilineRows?: number;
  tooltip?: { title: string };
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Dayjs | string;
  maxDate?: Dayjs | string;
  multiple?: boolean;
  limitTags?: number;
  InputLabelProps?: boolean;
  idcontainer?: string;
  customContainerClass?: string;
  id?: string;
  sx?: any;
  size?: any;
  disabledDates?: string[];
  modalInfo?: boolean;
  modalId?: string;
  ordinal?: number;
  marks?: any;
  step?: number;
  datePickerFormat?: boolean;
  views?: ("month" | "year" | "day")[];
  firstDay?: boolean;
  acceptedFile?: string;
}

export interface IMultiFormProps {
  inputs: IMultiFormField[];
  formTitle?: string | undefined | null;
  styles?: any;
  formId?: string;
  disableForm?: boolean | false;
  suppressLayout?: boolean;
  suppressSubmit?: boolean;
  submitButtonLabel?: string;
  classNameForm?: string;
  classNameFormGroup?: string;
  onSubmit?: (data: any) => void;
  onChange?: (data: any, event?: any) => void;
}

export const triggerFormValidation = (formId: string) => {
  let d = document.getElementById(formId + "-form-submit-event");
  if (d) {
    d.click();
  }
};

export const generateInputProps = (x: IMultiFormField) => {
  if (x.inputAdornament) {
    let props: any = { ...x.inputAdornament };
    if (props.adornament) {
      if (props.position && props.position === "start") {
        props.startAdornment = (
          <InputAdornment position="start">{props.adornament}</InputAdornment>
        );
      } else {
        props.endAdornment = (
          <InputAdornment position="start">{props.adornament}</InputAdornment>
        );
      }
    }

    return props;
  }

  return undefined;
};

export const inputPropsFunc = (x: IMultiFormField) => {
  const inputProps = { ...{ step: "any" }, ...x };

  return inputProps;
};

export const requestFormRefresh = () => {
  window.document.dispatchEvent(new Event("request-refresh-form"));
};

interface CustomSliderComponentProps extends React.HTMLAttributes<unknown> {}

const MultiForm = (props: IMultiFormProps) => {
  const [state, setState] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [form, setForm] = useState<IMultiFormField[]>([]);

  /* SLIDER */
  // STYLE
  const CustomSlider = styled(Slider)(({ theme }) => ({
    color: "#009fe3",
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 27,
      width: 27,
      backgroundColor: "#fff",
      border: "1px solid currentColor",
      "&:hover": {
        boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
      },
      "& .airbnb-bar": {
        height: 9,
        width: 1,
        backgroundColor: "currentColor",
        marginLeft: 1,
        marginRight: 1,
      },
    },
    "& .MuiSlider-track": {
      height: 3,
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
      height: 3,
    },
  }));

  const CustomSliderComponent = (props: CustomSliderComponentProps) => {
    const { children, ...other } = props;

    return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
      </SliderThumb>
    );
  };

  const getInputs = () => {
    return props.inputs.filter((x: IMultiFormField) => x.ignore !== true);
  };

  useEffect(() => {
    let inputs: IMultiFormField[] = getInputs();
    if (props.disableForm !== undefined) {
      setForm(
        inputs.map((x: IMultiFormField) => {
          if (props.disableForm) {
            x.disabled = props.disableForm;
          }
          return x;
        })
      );
    } else {
      setForm(inputs);
    }

    // Initialize state
    if (state === null) {
      let stats = inputs.map((x: IMultiFormField) => {
        let baseValue =
          x.defaultValue !== undefined && x.defaultValue !== null
            ? x.defaultValue
            : null;

        if (["checkbox", "switch"].includes(x.type) && baseValue === null) {
          baseValue = false;
        }
        return { key: x.name, value: baseValue };
      });
      let initialState: any = {};
      for (let i = 0; i < stats.length; i++) {
        initialState[stats[i].key] = stats[i].value;
      }
      setState(initialState);
    }
  }, [props.inputs, props.disableForm]);

  const validateRequireds = (state: any, onlyRemove: boolean) => {
    let cloneForm: IMultiFormField[] = [...form];
    for (let i = 0; i < cloneForm.length; i++) {
      let input: IMultiFormField = cloneForm[i];
      if (!input.disabled) {
        let badValue =
          state[input.name] === "" ||
          state[input.name] === undefined ||
          state[input.name] === null ||
          (state[input.name] && state[input.name].length === 0);
        if (input.required && badValue && !onlyRemove) {
          cloneForm[i]["errorText"] = "Required field";
        }
        if (input.required && !badValue) {
          cloneForm[i]["errorText"] = undefined;
        }
      }
    }
    setForm(cloneForm);
  };

  useEffect(() => {
    if (props.onChange && state !== null && !refresh) {
      props.onChange(state);
    }
  }, [state]);

  const updateChange = (name: string, value: any) => {
    let u: any = { ...state };
    u[name] = value;
    setState(u);
    validateRequireds(u, true);
  };

  const testSumbit = (e: any) => {
    if (props.onSubmit) {
      e.preventDefault();
      props.onSubmit(state);
    }
    return false;
  };

  const refreshForm = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 50);
  };

  const renderInputFinal = (x: IMultiFormField, inp: JSX.Element | null) => {
    return (
      <div>
        {false && <div>{x.defaultValue}</div>}
        {inp}
      </div>
    );
  };

  useEffect(() => {
    window.document.addEventListener("request-refresh-form", refreshForm);

    return () => {
      window.document.removeEventListener("request-refresh-form", refreshForm);
    };
  }, []);

  const renderForm = () => {
    let containerMap: any = {};
    form.forEach((x: IMultiFormField, i: number) => {
      let idcontainer = x.idcontainer ?? "default";
      if (containerMap[idcontainer] === undefined) {
        containerMap[idcontainer] = [];
      }
      containerMap[idcontainer].push(x);
    });

    return (
      <form
        onSubmit={testSumbit}
        id={props.formId ? "form-" + props.formId : ""}
        className={props?.classNameForm}
      >
        <FormGroup className={props?.classNameFormGroup}>
          {state !== null &&
            Object.values(containerMap).map((c: any, j: number) => {
              let form_: IMultiFormField[] = c;
              let containerClass: string = form_[0].customContainerClass ?? "";
              return (
                <div
                  key={j}
                  className={containerClass}
                  style={
                    props.styles || {
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }
                  }
                >
                  {form_.map((x: IMultiFormField, i: number) => {
                    let inp = null;
                    let valueAndDefault: any = {};
                    if (x.defaultValue || x.defaultValue >= 0) {
                      valueAndDefault["defaultValue"] = x.defaultValue;
                    }
                    if (x.currentValue) {
                      valueAndDefault["value"] = x.currentValue;
                    }

                    switch (x.type) {
                      case "text":
                      case "url":
                      case "email":
                      case "number":
                      case "decimal":
                      case "tel":
                        inp = (
                          <>
                            {x.tooltip ? (
                              <Tooltip title={x.tooltip.title} arrow>
                                <TextField
                                  {...valueAndDefault}
                                  fullWidth
                                  className={x.className}
                                  size={x.size ?? "small"}
                                  type={x.type}
                                  disabled={x.disabled}
                                  required={x.required}
                                  placeholder={x.placeholder as string}
                                  variant={x.variant || "outlined"}
                                  helperText={x.errorText || x.hint}
                                  error={
                                    x.errorText !== undefined &&
                                    x.errorText !== ""
                                  }
                                  InputProps={generateInputProps(x)}
                                  inputProps={x.inputProps}
                                  InputLabelProps={{
                                    shrink: x.InputLabelProps,
                                  }}
                                  onChange={(e: any) => {
                                    let value = e.target.value;
                                    if (x.type === "number" && value) {
                                      value = +value;
                                    }
                                    updateChange(x.name, value);
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <TextField
                                {...valueAndDefault}
                                fullWidth
                                className={x.className}
                                size={x.size ?? "small"}
                                type={x.type}
                                disabled={x.disabled}
                                required={x.required}
                                placeholder={x.placeholder as string}
                                variant={x.variant || "outlined"}
                                helperText={x.errorText || x.hint}
                                error={
                                  x.errorText !== undefined &&
                                  x.errorText !== ""
                                }
                                InputProps={generateInputProps(x)}
                                inputProps={inputPropsFunc(x.inputProps)}
                                InputLabelProps={{
                                  shrink: x.InputLabelProps,
                                }}
                                onChange={(e: any) => {
                                  let value = e.target.value;
                                  if (x.type === "number" && value) {
                                    value = +value;
                                  }
                                  updateChange(x.name, e.target.value);
                                }}
                              />
                            )}
                          </>
                        );
                        break;

                      case "select":
                        inp = (
                          <BetterSelect
                            {...x}
                            onChange={(v) => {
                              updateChange(x.name, v);
                            }}
                          />
                        );
                        break;
                      case "select_multiple":
                        inp = (
                          <BetterSelectMultiple
                            {...x}
                            onChange={(v) => {
                              updateChange(x.name, v);
                            }}
                          />
                        );
                        break;
                      case "date":
                        inp = (
                          <StrongDatePicker
                            {...x}
                            monthYear={x.datePickerFormat || null}
                            onChange={(v) => {
                              updateChange(x.name, v);
                            }}
                          />
                        );
                        break;
                      case "datetime":
                        inp = (
                          <StrongDateTimePicker
                            {...x}
                            onChange={(v) => {
                              updateChange(x.name, v);
                            }}
                          />
                        );
                        break;
                      case "time":
                        inp = (
                          <StrongTimePicker
                            {...x}
                            onChange={(v) => {
                              updateChange(x.name, v);
                            }}
                          />
                        );
                        break;
                      case "checkbox":
                        inp = (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size={x.size ?? "small"}
                                disabled={x.disabled}
                                defaultChecked={
                                  state[x.name] ? state[x.name] : x.defaultValue
                                }
                                onChange={(e: any) => {
                                  updateChange(x.name, e.target.checked);
                                }}
                              />
                            }
                            label={x.placeholder}
                          />
                        );
                        break;
                      case "switch":
                        inp = (
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={x.disabled}
                                defaultChecked={x.defaultValue}
                                onChange={(e: any) => {
                                  updateChange(x.name, e.target.checked);
                                }}
                              />
                            }
                            label={x.placeholder}
                          />
                        );
                        break;
                      case "multiline":
                        inp = (
                          <TextField
                            fullWidth
                            multiline
                            disabled={x.disabled}
                            rows={x.multilineRows}
                            defaultValue={
                              state[x.name] ? state[x.name] : x.defaultValue
                            }
                            required={x.required}
                            size={x.size ?? "small"}
                            placeholder={x.placeholder as string}
                            variant={x.variant || "outlined"}
                            helperText={x.errorText || x.hint}
                            error={
                              x.errorText !== undefined && x.errorText !== ""
                            }
                            onChange={(e: any) => {
                              updateChange(x.name, e.target.value);
                            }}
                          />
                        );
                        break;

                      case "password":
                        inp = (
                          <PasswordTextField
                            disabled={x.disabled}
                            defaultValue={
                              state[x.name] ? state[x.name] : x.defaultValue
                            }
                            required={x.required}
                            placeholder={x.placeholder as string}
                            InputProps={generateInputProps(x)}
                            inputProps={x.inputProps}
                            size={x.size ?? "small"}
                            variant={x.variant || "outlined"}
                            helperText={x.errorText || x.hint}
                            error={
                              x.errorText !== undefined && x.errorText !== ""
                            }
                            onChange={(e: any) => {
                              updateChange(x.name, e.target.value);
                            }}
                          />
                        );
                        break;
                      case "file":
                        inp = (
                          <div className="multi-form-upload-file">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                            >
                              <input
                                hidden
                                accept={x.acceptedFile || "*"}
                                type="file"
                                onChange={(e: any) => {
                                  updateChange(x.name, e.target.files);
                                }}
                              />
                              <AttachFileIcon />
                            </IconButton>

                            <Typography variant="overline">
                              {state[x.name] && state[x.name].length > 0
                                ? state[x.name][0].name
                                : ""}
                            </Typography>
                          </div>
                        );
                        break;
                      case "radio":
                        inp = (
                          <FormControl
                            size={x.size ?? "small"}
                            disabled={x.disabled}
                          >
                            <RadioGroup
                              row
                              value={
                                state[x.name] ? state[x.name] : x.defaultValue
                              }
                              name={x.name}
                              onChange={(e: any) => {
                                updateChange(x.name, e.target.value);
                              }}
                            >
                              {(x.options ?? []).map(
                                (
                                  x: {
                                    key: string | number | boolean;
                                    text: string;
                                  },
                                  i: number
                                ) => {
                                  return (
                                    <FormControlLabel
                                      key={i}
                                      value={x.key}
                                      control={<Radio />}
                                      label={x.text}
                                    />
                                  );
                                }
                              )}
                            </RadioGroup>
                          </FormControl>
                        );
                        break;
                      case "hidden":
                        inp = (
                          <TextField
                            type="hidden"
                            name={x.name}
                            className={x.className}
                            defaultValue={
                              state[x.name] ? state[x.name] : x.defaultValue
                            }
                            sx={{ display: "none" }}
                          />
                        );
                        break;
                      case "autocomplete":
                        inp = (
                          <Autocomplete
                            id={x.id}
                            multiple={x.multiple}
                            limitTags={x.limitTags}
                            size={x.size ?? "large"}
                            disabled={x.disabled}
                            // defaultValue={x.defaultValue}
                            // value={x.defaultValue}
                            noOptionsText={"No data"}
                            options={(x.options ?? []).map(
                              (x: {
                                key: string | number | boolean;
                                text: string;
                              }) => {
                                return {
                                  key: x.key,
                                  label: x.text,
                                };
                              }
                            )}
                            onChange={(e: any, value: any) => {
                              let uniqueValues;

                              if (value) {
                                if (x.multiple) {
                                  const updateValue = value.map(
                                    (x: any) => x.key
                                  );

                                  uniqueValues = Array.from(
                                    new Set(updateValue)
                                  );
                                } else {
                                  uniqueValues = value.key;
                                }

                                updateChange(x.name, uniqueValues);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size={x.size ?? "small"}
                                sx={x.sx}
                                variant={x.variant}
                                label={x.placeholder}
                                // required={x.required}
                                error={
                                  x.errorText !== undefined &&
                                  x.errorText !== ""
                                }
                              />
                            )}
                          />
                        );
                        break;
                      case "slider":
                        inp = (
                          <div className="padding-small">
                            <CustomSlider
                              valueLabelDisplay="auto"
                              slots={{ thumb: CustomSliderComponent }}
                              marks={x.marks}
                              defaultValue={x.defaultValue}
                              min={x.min}
                              max={x.max}
                              step={x.step}
                              name={x.name}
                              value={x.defaultValue}
                              onChange={(e: any) => {
                                updateChange(x.name, e.target.value);
                              }}
                            />
                          </div>
                        );
                        break;
                      case "custom":
                        inp = <div>{x.element}</div>;
                    }

                    const dePadWidth = (width: number | undefined) => {
                      if (width) {
                        return {
                          width: !x.fullWidth
                            ? "calc(" + width.toString() + "% - 15px"
                            : width.toString() + "%",
                        };
                      }

                      return {};
                    };

                    return x.type !== "hidden" ? (
                      <div
                        className={
                          "multi-form-input-wrap " + (x.className || "")
                        }
                        style={dePadWidth(x.width)}
                        id={x.id}
                        key={i}
                      >
                        {x.label && (
                          <div
                            className={
                              "multi-form-input-upper-label " +
                              (x.disabled ? "label-disabled" : "")
                            }
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: x.label }}
                            />
                            {x.required && " *"}
                            {x.modalInfo && x.modalId && (
                              <span
                                className="info-modal-form"
                                onClick={() => SummonModal(x.modalId as string)}
                              >
                                <i>i</i>
                              </span>
                            )}
                          </div>
                        )}
                        {renderInputFinal(x, inp)}
                      </div>
                    ) : (
                      <div hidden id={x.id}>
                        {renderInputFinal(x, inp)}
                      </div>
                    );
                  })}
                </div>
              );
            })}

          <div
            style={
              props.onSubmit && !props.suppressSubmit
                ? {
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "end",
                  }
                : { position: "absolute", top: "-1000px" }
            }
          >
            <div className="multi-form-button multi-form-input-wrap">
              <Button
                fullWidth
                id={props.formId ? props.formId + "-form-submit-event" : ""}
                type="submit"
                onClick={() => {
                  validateRequireds({ ...state }, false);
                }}
                disabled={props.disableForm}
                variant="contained"
                endIcon={
                  props.disableForm ? (
                    <CircularProgress size={25} />
                  ) : (
                    <ChevronRightIcon />
                  )
                }
              >
                {props.submitButtonLabel || "Salva"}
              </Button>
            </div>
          </div>
        </FormGroup>
      </form>
    );
  };
  return (
    <div
      className={
        props.suppressLayout
          ? "multi-form-main-wrap-layout-less"
          : "multi-form-main-wrap"
      }
    >
      {!props.suppressLayout && props.formTitle && (
        <div className="multi-form-title">{props.formTitle}</div>
      )}
      {!refresh && renderForm()}
      {refresh && renderForm()}
    </div>
  );
};

export default MultiForm;
