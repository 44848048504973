/* REACT */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/* STYLE */
import "./PageViewItem.scss";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* UTILITY */
import { parseData } from "../../Utils/ParseItemData";
import { ToastMessage } from "../../Utils/Toastify";

/* COMPONENTS */
import LoaderBackdrop from "../../components/LoaderBackdrop/LoaderBackdrop";
import ViewItemGrid from "../../components/ViewItemGrid/ViewItemGrid";

/* MUI */

/* COMPONENT */
const PageViewItem = () => {
  /* URL PARAMS */
  const location = useLocation();
  const searchSpl: string[] = window.location.href.split("?");
  const params = new URLSearchParams(
    searchSpl.length === 2 ? searchSpl[1] : location.search
  );
  const itemId = params.get("itemId");
  const itemType = params.get("itemType");
  const migrationToken = params.get("migrationToken");
  const validData = itemId && itemType && migrationToken;

  /* STATE */
  // Item details
  const [itemDetails, setItemDetails] = useState<any>(null);
  // Loading
  const [loadingData, setLoadingData] = useState(false);

  /* GET TASK */
  const getData = (
    itemType: string,
    itemId: number,
    migrationToken: string
  ) => {
    setLoadingData(true);

    // Mappa che collega i tipi di elemento ai metodi di controllo corrispondenti
    const controllerMap: {
      [key: string]: (
        itemId: number,
        migrationToken: string,
        callback: (response: IAPIResponse) => void
      ) => void;
    } = {
      AssistantTask: ApiService.TaskController.getTask,
      Notification: ApiService.NotificationController.getNotification,
      FileEntry: ApiService.FileController.getFile,
    };

    // Funzione di controllo corrispondente al tipo di elemento
    const controllerFunction = controllerMap[itemType];

    if (controllerFunction) {
      // Chiamare la funzione di controllo ottenuta dalla mappa
      controllerFunction(itemId, migrationToken, (response: IAPIResponse) => {
        if (response.error === null) {
          // Parse the response payload into the desired format
          let parsedData: any[] = [];

          if (response.payload && response.payload.length > 0) {
            parsedData = parseData(response.payload[0], itemType);
          }

          // Set the parsed data into state
          setItemDetails(parsedData);
        } else {
          ToastMessage("Error.", "error");
        }

        setLoadingData(false);
      });
    } else {
      // Tipo di elemento non valido, gestisci di conseguenza
      setLoadingData(false);

      ToastMessage("Error.", "error");
    }
  };

  /* RENDER DATA */
  useEffect(() => {
    if (itemId && itemType && migrationToken) {
      getData(itemType, +itemId, migrationToken);
    }
  }, [itemId, itemType, migrationToken]);

  /* RETURN */
  return (
    <div className="page-base-lvl-0 fadeIn">
      {!validData && (
        <div>
          <div className="ops-label">
            OPS! The target link is not properly formatted.
          </div>
          <div className="ops-label-minor">
            Please, verify the used link is compatible to our DeepLinking
            system.
          </div>
        </div>
      )}
      {validData && (
        <div>
          <div className="PageViewItem__container">
            <h2>Item Viewer</h2>

            <h6>
              The information relating to the selected item is listed below.
            </h6>

            {!loadingData && <ViewItemGrid itemDetails={itemDetails} />}
          </div>

          <LoaderBackdrop loading={loadingData} />
        </div>
      )}
    </div>
  );
};

export default PageViewItem;
