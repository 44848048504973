import PageInvoice from "./Pages/PageInvoice/PageInvoice";
import PageIssue from "./Pages/PageIssue/PageIssue";
import PageServiceRequest from "./Pages/PageServiceRequest/PageServiceRequest";
import PageOrder from "./Pages/PageOrder/PageOrder";
import PageDocuments from "./Pages/PageDocuments/PageDocuments";
import PageHome from "./Pages/PageHome/PageHome";
import PageLogin from "./Pages/PageLogin/PageLogin";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import PageNotifications from "./Pages/PageNotifications/PageNotifications";
import PageTasks from "./Pages/PageTasks/PageTasks";
import PageViewItem from "./Pages/PageViewItem/PageViewItem";
import TransferSessionPage from "./Pages/TransferSessionPage/TransferSessionPage";

export interface IAppRoute {
  path: string;
  element: JSX.Element;
  requresAuth: boolean;
}

export const AppRoutes = {
  BASE_ROUTE: "/",
  HOME_ROUTE: "/home",
  TASKS_ROUTE: "/tasks",
  NOTIFICATIONS_ROUTE: "/notifications",
  DOCUMENTS_ROUTE: "/documents",
  INVOICE_ROUTE: "/invoices",
  ORDER_ROUTE: "/purchases",
  ISSUES_ROUTE: "/issues",
  SERVICE_ROUTE: "/servicerequests",
  LOGIN_ROUTE: "/login",
  VIEW_ITEMS_ROUTE: "/viewItem",
  TRANSFER_SESSION_PAGE: "/transferSession",
};

export const AppRoutesMap: IAppRoute[] = [
  {
    path: AppRoutes.HOME_ROUTE,
    element: <PageHome />,
    requresAuth: true,
  },
  {
    path: AppRoutes.TASKS_ROUTE,
    element: <PageTasks />,
    requresAuth: true,
  },
  {
    path: AppRoutes.NOTIFICATIONS_ROUTE,
    element: <PageNotifications />,
    requresAuth: true,
  },
  {
    path: AppRoutes.DOCUMENTS_ROUTE,
    element: <PageDocuments />,
    requresAuth: true,
  },
  {
    path: AppRoutes.INVOICE_ROUTE,
    element: <PageInvoice />,
    requresAuth: true,
  },
  {
    path: AppRoutes.ISSUES_ROUTE,
    element: <PageIssue />,
    requresAuth: true,
  },
  {
    path: AppRoutes.SERVICE_ROUTE,
    element: <PageServiceRequest />,
    requresAuth: true,
  },
  {
    path: AppRoutes.ORDER_ROUTE,
    element: <PageOrder />,
    requresAuth: true,
  },
  {
    path: AppRoutes.TRANSFER_SESSION_PAGE,
    element: <TransferSessionPage />,
    requresAuth: false,
  },
  {
    path: AppRoutes.LOGIN_ROUTE,
    element: <PageLogin />,
    requresAuth: false,
  },
  {
    path: AppRoutes.VIEW_ITEMS_ROUTE,
    element: <PageViewItem />,
    requresAuth: false,
  },
  {
    path: AppRoutes.BASE_ROUTE,
    element: <PageLogin />,
    requresAuth: false,
  },
  {
    path: "*",
    element: <PageNotFound />,
    requresAuth: false,
  },
];
