const ApiEndPoints = {
  /* SESSION */
  delegatedLogin: "/Session/delegatedLogin",
  appDictionaries: "/Session/appDictionaries",
  selfInfo: "/Session/me",
  transferSession: "/Session/transferSession",

  /* TASKS */
  getTask: "/AssistantTask/get",
  getTaskMadeByMe: "/AssistantTask/get/madebyme",
  newTask: "/AssistantTask/new",
  updateTask: "/AssistantTask/update",
  deleteTask: "/AssistantTask/delete",

  /* NOTIFICATION */
  newNotification: "/Notification/new",
  getNotification: "/Notification/get",

  /* FILES */
  newFile: "/File/new",
  getFile: "/File/get",
  uploadFile: "/File/UploadFileBlob",

  /* ACTIVE DICRECORY */
  // Users
  ADUsers: "/Graph/getUsersInActiveDirectory",
  // Groups
  ADGroups: "/Graph/getGroupsInActiveDirectory",
};

export default ApiEndPoints;
