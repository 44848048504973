import { displayUTC0_ISODate } from "../components/MultiForm/SpecialInputs/StrongDatePicker";

export const parseData = (data: any, itemType: string) => {
  let parsedData: {
    id: number;
    title: string;
    description: string;
    priority: string;
    createdDate: string;
  } = {
    id: 0,
    title: "",
    description: "",
    priority: "",
    createdDate: "",
  };

  switch (itemType) {
    case "AssistantTask":
      parsedData.id = data.TaskId;
      parsedData.title = data.TaskTitle;
      parsedData.description = data.TaskDescription;
      parsedData.priority = data.PriorityLabel;
      parsedData.createdDate = displayUTC0_ISODate(data.CreatedDate);
      break;
    case "Notification":
      parsedData.id = data.NotificationId;
      parsedData.title = data.NotificationTitle;
      parsedData.description = data.NotificationDescription;
      parsedData.priority = data.PriorityLabel;
      parsedData.createdDate = displayUTC0_ISODate(data.CreatedDate);
      break;
    case "FileEntry":
      parsedData.id = data.ReferenceID;
      parsedData.title = data.ReferenceTitle;
      parsedData.description = data.ReferenceDescription;
      parsedData.priority = data.PriorityLabel;
      parsedData.createdDate = displayUTC0_ISODate(data.CreatedDate);
      break;
    default:
      break;
  }

  return [parsedData];
};
