/* ENDPOINT */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* QUERY STRING*/
import queryString from "query-string";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* MODELS */
import { INewNotification } from "../../Models/INotification";

/* CONTROLLER */
const NotificationController = {
  getNotification: async (
    notificationId: number,
    migrationToken?: string,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.getNotification;

    if (migrationToken) {
      request.extraHeaders = { SessionMigration: "Bearer " + migrationToken };
    }

    const parameters: Record<string, number> = {};

    if (notificationId) {
      parameters.notificationId = notificationId;
    }

    if (Object.keys(parameters).length > 0) {
      request.url += "?" + queryString.stringify(parameters);
    }

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  newNotification: async (
    body: INewNotification,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.newNotification;

    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },
};

export default NotificationController;
