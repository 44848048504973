import { FormControl, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/it";
import { useEffect, useState } from "react";
import { decodeDateLocale } from "../../../Utils/Decoder";
import { IMultiFormField } from "../MultiForm";

export interface IStrongDatePickerPropsInner extends IStrongDatePickerProps {
  value: Dayjs | null;
  handleChange: (newValue: Dayjs | null) => void;
}
export interface IStrongDatePickerProps extends IMultiFormField {
  firstDay?: boolean;
  onChange: (value: string) => void;
  monthYear?: boolean | null;
}

const readDateTime = (dateISO: string) => {
  if (dateISO) {
    let dt = new Date(dateISO);
  }
  return null;
};

export const padBlock = (block: string | number) => {
  return block.toString().padStart(2, "0");
};

export const dateFromUTC0_ISO = (utc0ISO: string) => {
  if (utc0ISO && utc0ISO.includes("T")) {
    let dtSpl = utc0ISO.replaceAll("Z", "").split("T");
    let dt = new Date(dtSpl[0]);
    dt.setHours(+dtSpl[1].split(":")[0]);
    dt.setMinutes(+dtSpl[1].split(":")[1]);
    dt.setSeconds(+dtSpl[1].split(":")[2]);
    return dt;
  }
  return null;
};

export const dateToUTC0_ISO = (date: Date) => {
  if (date) {
    let isoDate = `${date.getFullYear()}-${padBlock(
      date.getMonth() + 1
    )}-${padBlock(date.getDate())}T${padBlock(date.getHours())}:${padBlock(
      date.getMinutes()
    )}:${padBlock(date.getSeconds())}Z`;
    return isoDate;
  }
  return null;
};

export const dateIsPast = (testDate: string | undefined) => {
  if (testDate) {
    let dt = new Date(testDate);
    const now = new Date();
    if (dt <= now) {
      return true;
    }
  }
  return false;
};

export const displayUTC0_ISODate = (utc0ISO: string, noTime = false) => {
  if (utc0ISO && utc0ISO.includes("T")) {
    let dt = dateFromUTC0_ISO(utc0ISO);
    if (dt) {
      let str = dateToUTC0_ISO(dt);
      if (str) {
        str = str.replaceAll("Z", "");

        let date_str = str.split("T")[0];
        let time_str = str.split("T")[1];

        if (str.includes("NaN")) {
          str = "Invalid Date" ?? "";
        } else {
          str = noTime ? date_str : date_str + " " + time_str;
        }

        return str;
      }
    }
  }

  return "";
};

const StrongDatePicker = (props: IStrongDatePickerProps) => {
  const [value, setValue] = useState<Dayjs | null>(null);
  const [rerender, setRerender] = useState<boolean>(false);

  const doRerender = () => {
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 50);
  };

  useEffect(() => {
    if (props.defaultValue) {
      setValue(dayjs(dateFromUTC0_ISO(props.defaultValue)));
    }
  }, []);

  useEffect(() => {
    if (props.currentValue) {
      setValue(dayjs(dateFromUTC0_ISO(props.currentValue)));
      doRerender();
    }
  }, [props.currentValue]);

  useEffect(() => {
    if (value !== null) {
      let output: any = null;
      if (props.firstDay) {
        output = dateToUTC0_ISO(value.set("date", 1).toDate());
      } else {
        output = dateToUTC0_ISO(value.toDate());
      }
      if (output) {
        props.onChange(output);
      }
    }
  }, [value]);

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue?.isValid()) {
      setValue(newValue);
    }
  };

  return (
    <div>
      {!rerender && (
        <RenderDate {...props} handleChange={handleChange} value={value} />
      )}

      {rerender && (
        <RenderDate {...props} handleChange={() => {}} value={value} />
      )}
    </div>
  );
};

const RenderDate = (props: IStrongDatePickerPropsInner) => {
  const monthYear: any = props.monthYear
    ? { ...{ views: ["month", "year"] } }
    : {};

  const isDateDisabled = (date: Dayjs) => {
    if (props.disabledDates) {
      return props.disabledDates.includes(date.format("YYYY-MM-DD"));
    } else {
      return false;
    }
  };

  return (
    <FormControl fullWidth size="small">
      <LocalizationProvider
        adapterLocale={decodeDateLocale()}
        dateAdapter={AdapterDayjs}
      >
        <DatePicker
          {...monthYear}
          views={props.views ?? ["day", "month", "year"]}
          sx={{ height: "18px" }}
          disabled={props.disabled}
          value={props.value as any}
          disablePast={props?.disablePast ?? false}
          disableFuture={props?.disableFuture ?? false}
          onChange={props.handleChange}
          minDate={props?.minDate as any}
          maxDate={props?.maxDate as any}
          shouldDisableDate={isDateDisabled}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default StrongDatePicker;
