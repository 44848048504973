/* ENDPOINT */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* QUERY STRING*/
import queryString from "query-string";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* CONTROLLER */
const ADController = {
  getADUsers: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADUsers;

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  getADGroups: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.ADGroups;

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },
};

export default ADController;
