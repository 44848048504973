/* REACT */
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

/* STYLE */
import "bootstrap/dist/css/bootstrap.css";
import "./custom.css";

/* UTILS */
import "./Utils/Toastify";

import AppLayout from "./AppLayout";
import { AppRoutes } from "./AppRoutes";
import { useDispatch } from "react-redux";
import { GenericActions } from "./Redux/Generic/GenericAction";

/* COMPONENT */
const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* EXPIRED SESSION */
  const goToLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch((GenericActions as any).setMTAToken(undefined));
    dispatch((GenericActions as any).setAuthToken(undefined));
    dispatch((GenericActions as any).setUser(undefined));

    (window as any)["session-is-expired"] = true;
    navigate(AppRoutes.LOGIN_ROUTE);
  };

  (window as any)["goToLogin"] = goToLogin;

  /* RETURN */
  return (
    <div>
      <ToastContainer />

      <AppLayout />
    </div>
  );
};

export default App;
