/* MUI */
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

/* INTERFACE */
export interface ILoaderBackdrop {
  loading: boolean;
}

/* COMPONENT */
const LoaderBackdrop = (props: ILoaderBackdrop) => {
  /* RETURN */
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.loading}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default LoaderBackdrop;
