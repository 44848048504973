/* REACT */
import { useCallback, useEffect, useState } from "react";

/* SERVICES */
import ApiService from "../Services/ApiService";
import { IAPIResponse } from "../Services/Internal/AjaxService";

/* UTILITY */
import { ToastMessage } from "../Utils/Toastify";

/* MODELS */
import { IADGroups } from "../Models/IActiveDirectory";

/* HOOK */
const useADUsers = () => {
  /* STATE */
  const [ADGroups, setADGroups] = useState<any[]>([]);
  // Loading
  const [loadingADGroups, setLoadingADGroups] = useState(true);

  /* ACTIVE DIRECTORY GROUPS */
  const getADGroups = useCallback(() => {
    ApiService.ADController.getADGroups((response: IAPIResponse) => {
      setLoadingADGroups(false);

      if (response.error === null) {
        const groupsFiltered = response.payload.value.filter(
          (item: IADGroups) => item.displayName !== null
        );

        const groups = groupsFiltered.map((item: IADGroups) => ({
          key: item.id,
          text: item.displayName,
        }));

        setADGroups(groups);
      } else {
        ToastMessage("Error.", "error");
      }
    });
  }, []);

  /* RENDER USERS */
  useEffect(() => {
    getADGroups();
  }, [getADGroups]);

  /* RETURN */
  return { ADGroups, loadingADGroups };
};

export default useADUsers;
