import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./SmartModal.scss";

export interface ISmartModalButton {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  enabled?: boolean;
  startIcon?: JSX.Element;
  forcePrimary?: boolean;
}

export interface ISmartModalProps {
  modalUniqueId: string;
  title: string;
  modalInnerComponent: JSX.Element;
  loadingButtons?: boolean;
  buttons?: ISmartModalButton[];
  onAccept?: () => void;
  onReject?: () => void;
  hideButtons?: boolean | false;
  onBeforeSummon?: () => void;
  className?: string | "";
  labelAcceptButton?: string;
}

export const SummonModal = (modalUniqueId: string) => {
  window.document.dispatchEvent(new Event(modalUniqueId + "_modal_unique_key"));
};

export const DismissModal = (modalUniqueId: string) => {
  window.document.dispatchEvent(
    new Event("dismiss_" + modalUniqueId + "_modal_unique_key")
  );
};

const SmartModal = (props: ISmartModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [buttons, setButtons] = useState<ISmartModalButton[]>([]);

  useEffect(() => {
    setButtons(props.buttons ?? []);
  }, [props.buttons]);

  useEffect(() => {
    if (props.loadingButtons !== undefined) {
      setProcessing(props.loadingButtons);
    }
  }, [props.loadingButtons]);

  const modalSummon = () => {
    if (props.onBeforeSummon) {
      props.onBeforeSummon();
    }
    setVisible(true);
  };

  const modalDismiss = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.document.addEventListener(
      props.modalUniqueId + "_modal_unique_key",
      modalSummon
    );
    window.document.addEventListener(
      "dismiss_" + props.modalUniqueId + "_modal_unique_key",
      modalDismiss
    );
    return () => {
      window.document.removeEventListener(
        props.modalUniqueId + "_modal_unique_key",
        modalSummon
      );
      window.document.removeEventListener(
        "dismiss_" + props.modalUniqueId + "_modal_unique_key",
        modalDismiss
      );
    };
  }, []);

  const defaultButtons: ISmartModalButton[] = [
    {
      enabled: props.onReject !== undefined,
      text: "Cancel",
      onClick: () => {
        if (props.onReject) {
          setProcessing(true);
          props.onReject();
          setProcessing(false);
        }
        setVisible(false);
      },
    },
    {
      enabled: props.onAccept !== undefined,
      text: props.labelAcceptButton || "Conferma",
      onClick: () => {
        if (props.onAccept) {
          setProcessing(true);
          props.onAccept();
          setProcessing(false);
        }
        setVisible(false);
      },
    },
  ];

  const buttonsToUse = buttons.length > 0 ? buttons : defaultButtons;

  return (
    <>
      {visible && (
        <Dialog
          className={props.className}
          open={visible}
          onClose={() => {
            setVisible(false);
          }}
          aria-labelledby={props.title}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "1000px",
            },
          }}
        >
          <DialogTitle>
            <div className="smart-modal-title">
              <div id="modal-title">{props.title}</div>
              <IconButton
                onClick={() => {
                  setVisible(false);
                }}
              >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent style={{ paddingBottom: "0" }}>
            {visible && <div>{props.modalInnerComponent}</div>}
          </DialogContent>

          {!props.hideButtons ? (
            <div className="smart-modal-button-zone">
              <div></div>

              {processing && <CircularProgress />}

              {!processing && !props.hideButtons && (
                <div
                  style={{ display: "flex", gap: "1em", margin: "0 1em 1em" }}
                >
                  {buttonsToUse
                    .filter(
                      (x: ISmartModalButton) =>
                        x.enabled || x.enabled === undefined
                    )
                    .map((x: ISmartModalButton, i: number) => {
                      return (
                        <Button
                          key={i}
                          startIcon={x.startIcon}
                          variant={
                            x.forcePrimary ||
                            i ===
                              buttonsToUse.filter(
                                (x: ISmartModalButton) =>
                                  x.enabled || x.enabled === undefined
                              ).length -
                                1
                              ? "contained"
                              : "outlined"
                          }
                          disabled={x.disabled}
                          onClick={() => {
                            setProcessing(true);
                            x.onClick();
                            setProcessing(false);
                          }}
                        >
                          {x.text}
                        </Button>
                      );
                    })}
                </div>
              )}
            </div>
          ) : (
            <div style={{ margin: "0.25em" }}></div>
          )}
        </Dialog>
      )}
    </>
  );
};

export default SmartModal;
