/* REACT */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* STYLE */
import "./PageNotifications.scss";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* UTILITY */
import { ToastMessage } from "../../Utils/Toastify";

/* MODELS */
import { INewNotification } from "../../Models/INotification";
import { IAppDictionary } from "../../Models/IAppDictionaries";

/* CUSTOM HOOKS */
import useADUsers from "../../Hook/useADUsers";
import useADGroups from "../../Hook/useADGroups";

/* UTILS */
import { cleanFormData } from "../../Utils/CleanForm";

/* COMPONENTS */
import MultiForm from "../../components/MultiForm/MultiForm";
import LoaderBackdrop from "../../components/LoaderBackdrop/LoaderBackdrop";

/* COMPONENT */
const PageNotifications = () => {
  /* STATE */
  const [formData, setFormData] = useState<any>(null);
  // Merged AD
  const [mergedAD, setMergedAD] = useState<any[]>([]);
  // Clean form fields
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  // Loading
  const [loadingInsertNotification, setLoadingInsertNotification] =
    useState(false);

  /* CUSTOM HOOK */
  const { ADGroups, loadingADGroups } = useADGroups();
  const { ADUsers, loadingADUsers } = useADUsers();
  const appDictionaries: IAppDictionary[] = useSelector(
    (state: any) => state.generic.appDictionaries
  );

  /* MERGED AD */
  useEffect(() => {
    let mergedArray: { key: string; text: string }[] = ADGroups.concat(
      ADUsers.map((item: any) => {
        return { key: item.key, text: item.text };
      })
    );

    mergedArray = mergedArray.filter(
      (x: any) =>
        x.key === "demouser@plan4utech.onmicrosoft.com" ||
        x.key === "demouser2@plan4utech.onmicrosoft.com"
    );

    setMergedAD(mergedArray);
  }, [loadingADUsers || loadingADGroups]);

  /* CLEAN FORM FIELDS */
  const handleCleanFormData = () => {
    cleanFormData(setRefreshForm);
  };

  /* INSERT */
  const newNotification = (body: INewNotification) => {
    setLoadingInsertNotification(true);

    ApiService.NotificationController.newNotification(
      body,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage("Notification inserted correctly.", "success");

          // Resetta lo stato formData dopo l'inserimento riuscito
          setFormData(null);

          // Clean form fields
          handleCleanFormData();
        } else {
          ToastMessage(response.error, "error");
        }

        setLoadingInsertNotification(false);
      }
    );
  };

  /* RETURN */
  return (
    <div className="page-base-lvl-0 fadeIn">
      <div className="PageNotifications__container">
        <h2>Notifications</h2>

        <h6>
          Please input information in the form below about a new notification in
          the Digital Workplace Demo App
        </h6>

        {!loadingADUsers &&
          !loadingADGroups &&
          (ADUsers.length > 0 || ADGroups.length > 0) &&
          !refreshForm && (
            <MultiForm
              suppressLayout
              formId="form-page-notifications"
              submitButtonLabel="Send"
              inputs={[
                {
                  width: 50,
                  type: "text",
                  name: "NotificationTitle",
                  label: "Title",
                  defaultValue: "",
                  required: true,
                },
                {
                  width: 50,
                  type: "select",
                  required: true,
                  name: "NotificationPriority",
                  defaultValue: [],
                  placeholder: "",
                  label: "Priority",
                  options: appDictionaries
                    .filter(
                      (item: any) => item.DictionaryType === "priority-level"
                    )
                    .map(
                      (x: {
                        DictionaryId: number;
                        DictionaryDisplayName: string;
                      }) => {
                        return {
                          key: x.DictionaryId,
                          text: x.DictionaryDisplayName,
                        };
                      }
                    ),
                },
                {
                  width: 50,
                  multiple: true,
                  type: "select",
                  required: true,
                  name: "TargetEmails",
                  defaultValue: formData?.TargetEmails ?? [],
                  placeholder: "",
                  label: "Assigned To",
                  options: mergedAD,
                },
                {
                  width: 50,
                  type: "select",
                  required: true,
                  name: "NotificationType",
                  defaultValue: [],
                  placeholder: "",
                  label: "Type",
                  options: [
                    { key: "database", text: "External application" },
                    { key: "automatechatbot", text: "Power Automate" },
                  ].map((x: { key: string; text: string }) => {
                    return {
                      key: x.key,
                      text: x.text,
                    };
                  }),
                },
                {
                  width: 100,
                  type: "multiline",
                  label: "Description",
                  name: "NotificationDescription",
                  required: true,
                  multilineRows: 2,
                },
              ]}
              onChange={(data: any) => {
                setFormData({ ...formData, ...data });
              }}
              onSubmit={(data: any) => {
                data["TargetEmails"] = data["TargetEmails"].join(",");
                data["NotificationStatus"] = "ToRead";
                data["NotificationCallToAction"] = "";
                data["NotificationExternalSystemLink"] =
                  "https://demoteams-integrations.r53.avvale.com/#/viewItem";

                newNotification(data);
              }}
            />
          )}
      </div>

      <LoaderBackdrop
        loading={loadingADGroups || loadingADUsers || loadingInsertNotification}
      />
    </div>
  );
};

export default PageNotifications;
