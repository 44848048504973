/* ENDPOINT */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* QUERY STRING*/
import queryString from "query-string";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* MODELS */
import { IDeleteTask, INewTask, IUpdateTask } from "../../Models/ITask";

/* CONTROLLER */
const TaskController = {
  getTask: async (
    taskId: number,
    migrationToken?: string,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.getTask;

    const parameters: Record<string, number> = {};

    if (migrationToken) {
      request.extraHeaders = { SessionMigration: "Bearer " + migrationToken };
    }

    if (taskId) {
      parameters.taskId = taskId;
    }

    if (Object.keys(parameters).length > 0) {
      request.url += "?" + queryString.stringify(parameters);
    }

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  getTaskMadeByMe: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.getTaskMadeByMe;

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  newTask: async (
    body: INewTask,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.newTask;

    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  updateTask: async (
    body: IUpdateTask,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.updateTask;

    request.method = "PUT";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      return callback(response);
    }

    return response;
  },

  deleteTask: async (
    body: IDeleteTask,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.deleteTask;

    request.method = "DELETE";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };

    request.skipResponseJsonParse = true;

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      return callback(response);
    }
    return response;
  },
};

export default TaskController;
