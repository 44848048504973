import SessionController from "./Controllers/SessionController";
import ADController from "./Controllers/ADController";
import TaskController from "./Controllers/TaskController";
import NotificationController from "./Controllers/NotificationController";
import FileController from "./Controllers/FileController";

const ApiService: any = {
  SessionController,
  ADController,
  TaskController,
  NotificationController,
  FileController,
};

if (window.location.href.indexOf("localhost") !== -1) {
  (window as any)["ApiService"] = ApiService;
}

export default ApiService;
