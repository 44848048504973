import { FormControl } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { decodeDateLocale } from "../../../Utils/Decoder";
import { IMultiFormField } from "../MultiForm";

export interface IStrongTimePickerProps extends IMultiFormField {
  onChange: (value: string) => void;
}

const StrongTimePicker = (props: IStrongTimePickerProps) => {
  const [value, setValue] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (props.defaultValue) {
      setValue(dayjs(props.defaultValue));
    }
  }, []);

  useEffect(() => {
    if (value !== null) {
      props.onChange(value.toISOString());
    }
  }, [value]);

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue?.isValid()) {
      setValue(newValue);
    }
  };

  return (
    <div>
      <FormControl fullWidth size={props.size ?? "small"}>
        <LocalizationProvider
          adapterLocale={decodeDateLocale()}
          dateAdapter={AdapterDayjs}
        >
          <TimePicker
            disabled={props.disabled}
            label={props.placeholder}
            value={value}
            onChange={handleChange}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

export default StrongTimePicker;
