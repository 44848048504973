import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var debuffErrors = false;
export const ToastMessage = (
  message: string,
  type: "error" | "success" | "warning" | "info"
) => {
  if (!message && type === "error") {
    message = "Qualcosa è andato storto. Per favore, riprovare a breve.";
  }

  if (debuffErrors && type === "error") {
    return;
  }

  if (type === "error") {
    debuffErrors = true;
    setTimeout(() => {
      debuffErrors = false;
    }, 500);
  }

  ToastMessageInner(message, type);
};

export const ToastMessageInner = (
  message: string,
  type: "error" | "success" | "warning" | "info"
) => {
  if (
    message &&
    message.toLocaleLowerCase() === "failed to fetch" &&
    !navigator.onLine
  ) {
    message = "Sei offline.";
  }

  if ((window as any)["suppressToasts"]) {
    return;
  }

  if (type === "success") {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  if (type === "warning") {
    toast.warning(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  if (type === "info") {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }
};

(window as any)["ToastMessage"] = ToastMessage;
