/* REACT */
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

/* STYLE */
import "./PageInvoice.scss";

/* SERVICES */
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";

/* UTILITY */
import { ToastMessage } from "../../Utils/Toastify";

/* MODELS */
import { IAppDictionary } from "../../Models/IAppDictionaries";
import { IGetTask, INewTask } from "../../Models/ITask";

/* CUSTOM HOOKS */
import useADUsers from "../../Hook/useADUsers";
import useADGroups from "../../Hook/useADGroups";

/* UTILS */
import { cleanFormData } from "../../Utils/CleanForm";

/* COMPONENTS */
import MultiForm from "../../components/MultiForm/MultiForm";
import LoaderBackdrop from "../../components/LoaderBackdrop/LoaderBackdrop";
import DataGridTable from "../../components/DataGrid/DataGrid";

/* MUI */
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* COMPONENT */
const PageInvoice = () => {
  /* STATE */
  // Form data
  const [formData, setFormData] = useState<any>(null);
  // Remount the form
  const [formKey, setFormKey] = useState<number>(0);
  // Tasks list
  const [tasksList, setTasksList] = useState<IGetTask[]>([]);
  // Merged AD
  const [mergedAD, setMergedAD] = useState<any[]>([]);
  // Clean form fields
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  // Loading
  const [loadingGetMadeByMeTask, setLoadingGetMadeByMeTask] = useState(false);
  const [loadingInsertTask, setLoadingInsertTask] = useState(false);

  /* CUSTOM HOOK */
  const { ADGroups, loadingADGroups } = useADGroups();
  const { ADUsers, loadingADUsers } = useADUsers();

  /* DICTIONARIES */
  const appDictionaries: IAppDictionary[] = useSelector(
    (state: any) => state.generic.appDictionaries
  );

  /* MERGED AD */
  useEffect(() => {
    let mergedArray: { key: string; text: string }[] = ADGroups.concat(
      ADUsers.map((item: any) => {
        return { key: item.key, text: item.text };
      })
    );

    mergedArray = mergedArray.filter(
      (x: any) =>
        x.key === "demouser@plan4utech.onmicrosoft.com" ||
        x.key === "demouser2@plan4utech.onmicrosoft.com"
    );

    setMergedAD(mergedArray);
  }, [loadingADUsers || loadingADGroups]);

  /* CLEAN FORM FIELDS */
  const handleCleanFormData = () => {
    setFormData(null);
    cleanFormData(setRefreshForm);
  };

  /* GET TASKS MADE BY ME */
  const getTaskMadeByMe = () => {
    setLoadingGetMadeByMeTask(true);

    ApiService.TaskController.getTaskMadeByMe((response: IAPIResponse) => {
      if (response.error === null) {
        setTasksList(response.payload);
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingGetMadeByMeTask(false);
    });
  };

  /* INSERT */
  const newTask = (body: INewTask) => {
    setLoadingInsertTask(true);

    ApiService.TaskController.newTask(body, (response: IAPIResponse) => {
      if (response.error === null) {
        ToastMessage("Invoice entered correctly.", "success");

        // Refresh tasks list
        getTaskMadeByMe();

        // Resetta lo stato formData dopo l'inserimento riuscito
        setFormData(null);

        // Clean form fields
        handleCleanFormData();
      } else {
        ToastMessage(response.error, "error");
      }

      setLoadingInsertTask(false);
    });
  };

  /* RENDER PAGE */
  useEffect(() => {
    getTaskMadeByMe();
  }, []);

  /* RETURN */
  return (
    <div className="page-base-lvl-0 fadeIn">
      <div className="PageInvoice__container">
        <h2>Invoice</h2>

        <h6>
          Please input information in the form below about a new invoice in the
          Digital Workplace Demo App
        </h6>

        {!loadingADUsers &&
          !loadingADGroups &&
          (ADUsers.length > 0 || ADGroups.length > 0) && (
            <Accordion defaultExpanded elevation={3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="h6">Add Invoice</Typography>
              </AccordionSummary>

              <AccordionDetails>
                {!refreshForm && (
                  <MultiForm
                    key={formKey}
                    suppressLayout
                    formId="form-page-invoice"
                    submitButtonLabel="Send"
                    inputs={[
                      {
                        width: 50,
                        type: "text",
                        name: "TaskTitle",
                        label: "Title",
                        defaultValue: formData?.Title ?? "",
                        required: true,
                      },
                      {
                        width: 50,
                        multiple: true,
                        type: "select",
                        required: true,
                        name: "TargetEmails",
                        defaultValue: formData?.TargetEmails ?? [],
                        placeholder: "",
                        label: "Assigned To",
                        options: mergedAD,
                      },
                      {
                        width: 100,
                        type: "multiline",
                        label: "Description",
                        name: "TaskDescription",
                        defaultValue: formData?.TaskDescription ?? "",
                        required: true,
                        multilineRows: 2,
                      },
                      {
                        width: 33.3,
                        type: "select",
                        required: true,
                        name: "TaskPriority",
                        defaultValue: formData?.Priority ?? [],
                        placeholder: "",
                        label: "Priority",
                        options: appDictionaries
                          .filter(
                            (item: any) =>
                              item.DictionaryType === "priority-level"
                          )
                          .map(
                            (x: {
                              DictionaryId: number;
                              DictionaryDisplayName: string;
                            }) => {
                              return {
                                key: x.DictionaryId,
                                text: x.DictionaryDisplayName,
                              };
                            }
                          ),
                      },
                      {
                        width: 33.3,
                        type: "select",
                        required: true,
                        name: "TaskField",
                        defaultValue: formData?.TaskField ?? [],
                        placeholder: "",
                        label: "Field",
                        options: appDictionaries
                          .filter(
                            (item: any) =>
                              item.DictionaryType === "scope-option"
                          )
                          .map((x: IAppDictionary) => {
                            return {
                              key: x.DictionaryDisplayName,
                              text: x.DictionaryDisplayName,
                            };
                          }),
                      },
                      {
                        width: 33.3,
                        multiple: true,
                        type: "select",
                        required: false,
                        name: "TaskCallToAction",
                        defaultValue: formData?.TaskCallToAction ?? [],
                        placeholder: "",
                        label: "CTA",
                        options: appDictionaries
                          .filter(
                            (item: any) => item.DictionaryType === "cta-option"
                          )
                          .map((x: IAppDictionary) => {
                            return {
                              key: x.DictionaryDisplayName,
                              text: x.DictionaryDisplayName,
                            };
                          }),
                      },
                    ]}
                    onChange={(data: any) => {
                      setFormData({ ...formData, ...data });
                    }}
                    onSubmit={(data: any) => {
                      data["TargetEmails"] = data["TargetEmails"].join(",");
                      data["TaskCallToAction"] =
                        data.TaskCallToAction.join(",");
                      data["TaskStatus"] = "Assigned";
                      data["TaskProcess"] = "Invoices";
                      data["TaskExternalSystemLink"] =
                        "https://demoteams-integrations.r53.avvale.com/#/viewItem";

                      newTask(data);
                    }}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}

        {!loadingGetMadeByMeTask && <DataGridTable dataList={tasksList} />}
      </div>

      <LoaderBackdrop
        loading={
          loadingGetMadeByMeTask ||
          loadingADUsers ||
          loadingADGroups ||
          loadingInsertTask
        }
      />
    </div>
  );
};

export default PageInvoice;
