/* ENDPOINT */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* QUERY STRING*/
import queryString from "query-string";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* CONTROLLER */
const SessionController = {
  delegatedLogin: async (
    entityIdentifier: string,
    secret: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.delegatedLogin;

    const parameters: Record<string, string> = {};

    if (entityIdentifier) {
      parameters.entityIdentifier = entityIdentifier;
    }

    if (secret) {
      parameters.secret = secret;
    }

    if (Object.keys(parameters).length > 0) {
      request.url += "?" + queryString.stringify(parameters);
    }

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },
  transferSession: async (
    migrationToken: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.transferSession;

    request.method = "GET";

    if (migrationToken) {
      request.extraHeaders = { SessionMigration: "Bearer " + migrationToken };
    }

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },
  getSelfInfos: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.selfInfo;

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },
  appDictionaries: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.appDictionaries;

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },
};

export default SessionController;
