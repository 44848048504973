/* REACT */
import ReactDOM from "react-dom/client";
import { HashRouter, useNavigate } from "react-router-dom";

/* COMPONENT */
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/Store";

/* MUI */
import { createTheme, ThemeProvider } from "@mui/material/styles";

/* THEME */
export const theme = createTheme({
  palette: {
    primary: {
      main: "#387cfa",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#555555",
    },
  },
});

/* ROOT */
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/* RENDER */
root.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  </ThemeProvider>
);
