/* STYLE */
import "./ViewItemGrid.scss";

/* MODELS */
import { IViewItem } from "../../Models/IViewItem";

/* MUI */
import {
  Alert,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";

/* INTERFACE */
export interface IViewItemGrid {
  itemDetails: IViewItem[];
}

/* COMPONENT */
const ViewItemGrid = (props: IViewItemGrid) => {
  /* RETURN */
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Title</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Priority</TableCell>
            <TableCell align="right">Created Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.itemDetails && props.itemDetails.length > 0 ? (
            props.itemDetails.map((item) => (
              <TableRow
                key={item.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell align="right">{item.title}</TableCell>
                <TableCell align="right">{item.description}</TableCell>
                <TableCell align="right">{item.priority}</TableCell>
                <TableCell align="right">{item.createdDate}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="info">No Data</Alert>
                </Stack>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewItemGrid;
