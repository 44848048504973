import { IAppDictionary } from "../../Models/IAppDictionaries";
import { IUser } from "../../Models/IUser";

export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_MTA_TOKEN = "SET_MTA_TOKEN";
export const SET_APP_DICTIONARIES = "SET_APP_DICTIONARIES";

interface SetAppDictionaries {
  type: typeof SET_APP_DICTIONARIES;
  payload: IAppDictionary[];
}

interface SetUser {
  type: typeof SET_USER;
  payload: IUser | undefined;
}

interface SetToken {
  type: typeof SET_TOKEN;
  payload: string | undefined;
}

interface SetMTAToken {
  type: typeof SET_MTA_TOKEN;
  payload: string | undefined;
}

export type GenericAction =
  | SetUser
  | SetToken
  | SetMTAToken
  | SetAppDictionaries;

const setAppDictionaries = (
  dictionaries: IAppDictionary[]
): SetAppDictionaries => {
  return { type: SET_APP_DICTIONARIES, payload: dictionaries };
};

const setUser = (user: IUser | undefined): SetUser => {
  return { type: SET_USER, payload: user };
};

const setAuthToken = (token: string | undefined): SetToken => {
  return { type: SET_TOKEN, payload: token };
};

const setMTAToken = (token: string | undefined): SetMTAToken => {
  return { type: SET_MTA_TOKEN, payload: token };
};

export const GenericActions = {
  setUser,
  setAuthToken,
  setAppDictionaries,
  setMTAToken,
};
