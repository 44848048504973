/* ENDPOINT */
import ApiEndPoints from "../../Costants/ApiEndPoints";

/* QUERY STRING*/
import queryString from "query-string";

/* SERVICES */
import {
  AjaxService,
  IAPIRequest,
  IAPIResponse,
  createEmptyRequest,
} from "../Internal/AjaxService";

/* MODELS */
import { INewFile } from "../../Models/IFile";

/* CONTROLLER */
const FileController = {
  getFile: async (
    fileId: number,
    migrationToken?: string,

    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.getFile;

    if (migrationToken) {
      request.extraHeaders = { SessionMigration: "Bearer " + migrationToken };
    }

    const parameters: Record<string, number> = {};

    if (fileId) {
      parameters.fileId = fileId;
    }

    if (Object.keys(parameters).length > 0) {
      request.url += "?" + queryString.stringify(parameters);
    }

    request.method = "GET";

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  newFile: async (
    body: INewFile,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.newFile;

    request.method = "POST";
    request.payload = body;
    request.extraHeaders = { "Content-Type": "application/json" };

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }

    return response;
  },

  uploadFile: async (
    containerName: string,
    body: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ApiEndPoints.uploadFile;

    const formData = new FormData();

    for (let i = 0; i < body.length; i++) {
      formData.append("Files", body[i]);
    }

    const parameters: Record<string, string> = {};

    if (containerName) {
      parameters.containerName = containerName;
    }

    if (Object.keys(parameters).length > 0) {
      request.url += "?" + queryString.stringify(parameters);
    }

    request.method = "POST";
    request.payload = formData;
    request.dontStringify = true;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FileController;
