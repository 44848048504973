/* STYLE */
import "./PageNotFound.scss";

/* COMPONENT */
const PageNotFound = () => {
  /* RETURN */
  return (
    <div className="page-base-lvl-0 fadeIn">
      <div className="Home__container">
        <h2 style={{ fontSize: "5em" }}>404</h2>
        <h1>Page not found!</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
