/* REACT */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* MUI */
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";

/* MUI */
import { Avatar, Badge, IconButton, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { IUser } from "../../Models/IUser";

/* INTERFACE */
export interface IHeaderPage {}

/* COMONENT */
const HeaderPage = (props: IHeaderPage) => {
  /* NAVIGATE */
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );

  /* BADGE */
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  /* RETURN */
  return (
    <div className="page-navigator-toolbar">
      <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
        <div>{loggedUser?.UserEmail}</div>
        <div className="page-navigator-box-logo">
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar />
          </StyledBadge>
        </div>
        <IconButton
          color="inherit"
          onClick={() => {
            (window as any)["goToLogin"]();
          }}
        >
          <LogoutIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default HeaderPage;
