import { useDispatch } from "react-redux";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/Internal/AjaxService";
import "./TransferSessionPage.scss";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import { AppRoutes } from "../../AppRoutes";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastMessage } from "../../Utils/Toastify";
import LoaderBackdrop from "../../components/LoaderBackdrop/LoaderBackdrop";

const TransferSessionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchSpl: string[] = window.location.href.split("?");
  const params = new URLSearchParams(
    searchSpl.length === 2 ? searchSpl[1] : location.search
  );
  const migrationToken = params.get("migrationToken") ?? "";
  const redirectAfter = params.get("redirectAfter") ?? "";

  const [loading, setLoading] = useState(false);

  const TransferSession = () => {
    setLoading(true);
    ApiService.SessionController.transferSession(
      migrationToken,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(
            (GenericActions as any).setMTAToken(
              response.payload.Token as string
            )
          );
          navigate(redirectAfter);
        } else {
          ToastMessage(
            "Error during session migration. The session miration token might be expired or invalid. Please try again.",
            "error"
          );
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    TransferSession();
  }, []);

  return (
    <div>
      <LoaderBackdrop loading={loading} />
    </div>
  );
};

export default TransferSessionPage;
