/* REACT */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* ROUTES */
import { AppRoutes } from "../../AppRoutes";

/* MATERIAL UI */
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";

interface IMenuItem {
  name: string;
  navigationLink: string;
  icon: JSX.Element;
}

/* NAV DATA */
const menuItems: IMenuItem[] = [
  {
    name: "Home",
    navigationLink: AppRoutes.HOME_ROUTE,
    icon: <HomeOutlinedIcon />,
  },
  {
    name: "Notifications",
    navigationLink: AppRoutes.NOTIFICATIONS_ROUTE,
    icon: <MarkEmailUnreadOutlinedIcon />,
  },
  {
    name: "Documents",
    navigationLink: AppRoutes.DOCUMENTS_ROUTE,
    icon: <FolderCopyOutlinedIcon />,
  },
  {
    name: "Invoice",
    navigationLink: AppRoutes.INVOICE_ROUTE,
    icon: <PaidOutlinedIcon />,
  },
  {
    name: "Purchase order",
    navigationLink: AppRoutes.ORDER_ROUTE,
    icon: <ShoppingCartOutlinedIcon />,
  },
  {
    name: "Issue",
    navigationLink: AppRoutes.ISSUES_ROUTE,
    icon: <ErrorOutlineOutlinedIcon />,
  },
  {
    name: "Service request",
    navigationLink: AppRoutes.SERVICE_ROUTE,
    icon: <DesignServicesOutlinedIcon />,
  },
];

/* COMPONENT */
const PageNavigator = () => {
  /* NAVIGATE */
  const navigate = useNavigate();

  /* STATE */
  const [navMenuItems] = useState<IMenuItem[]>(menuItems);

  /* RETURN */
  return (
    <div className="page-navigator-wrap">
      <div className={"page-navigator-lvl-0"}>
        <div className="page-navigator-nav-boxLogo">
          <img
            className="page-navigator-nav-logo"
            src="/assets/logo-demo.svg"
            alt="Logo Demo"
          />
        </div>

        {navMenuItems.length > 0 &&
          navMenuItems.map((item: IMenuItem, i: number) => {
            return (
              <button
                key={i}
                className={
                  "page-navigator-nav-item" +
                  (window.location.hash === "#" + item.navigationLink
                    ? " page-navigator-nav-item-selected"
                    : "")
                }
                onClick={() => {
                  navigate(item.navigationLink);
                }}
              >
                {item.icon}
                <span
                  className={
                    "page-navigator-nav-item-label" +
                    (window.location.hash === "#" + item.navigationLink
                      ? " page-navigator-nav-item-label-selected"
                      : "")
                  }
                >
                  {item.name}
                </span>
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default PageNavigator;
