import { IMultiFormField } from "../MultiForm";

import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./BetterSelect.scss";

/* INTEFACE */
export interface IBetterSelectProps extends IMultiFormField {
  onChange: (value: string, event?: any) => void;
  loading?: boolean | false;
}

/* SELECT */
const BetterSelectInner = (props: IBetterSelectProps) => {
  let helperText = props.errorText ? props.errorText : props.hint;
  let errored = props.errorText !== undefined && props.errorText !== "";

  // Set max height of the select window
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };

  // Select propeties
  let selectProperties: any = [];

  // Basic propeties
  selectProperties = {
    ...props,
    error: errored,
    // labelId: props.name + "_select_placeholder",
    onChange: (e: any) => {
      props.onChange(e.target.value);
    },
    MenuProps: MenuProps,
  };

  // Set inital value for the select
  if (props.currentValue !== undefined && props.currentValue !== null) {
    selectProperties["value"] = props.currentValue;
  }

  // clean errors on custom attributes
  delete selectProperties.errorText;
  delete selectProperties.customContainerClass;

  const sortOptions = (
    rawOptions: { key: string | number | boolean; text: string }[] | undefined
  ) => {
    if (rawOptions) {
      return rawOptions.sort(
        (
          a: { key: string | number | boolean; text: string },
          b: { key: string | number | boolean; text: string }
        ) => {
          return a.text.localeCompare(b.text);
        }
      );
    }
    return [];
  };

  /* RETURN */
  return (
    <div>
      <FormControl
        className="better-select-input"
        fullWidth
        disabled={props.disabled || props.loading}
        required={props.required}
        error={errored}
        placeholder={props.placeholder ?? ""}
        size={props.size ?? "small"}
        variant={props.variant ? props.variant : "outlined"}
        sx={{ minWidth: 200 }}
      >
        {!props.multiple ? (
          <Select {...selectProperties}>
            {(sortOptions(props.options) ?? []).map(
              (x: { key: any; text: string; disabled?: boolean }, i: number) => {
                return (
                  <MenuItem disabled={x.disabled} key={i} value={x.key}>
                    {x.text}
                  </MenuItem>
                );
              }
            )}
          </Select>
        ) : (
          <Select
            multiple
            renderValue={(selected: any) => {
              return (sortOptions(props.options) ?? [])
                ?.filter((x: { key: string | number | boolean; text: string }) => {
                  return selected.indexOf(x.key) > -1;
                })
                .map((x: { key: string | number | boolean; text: string }, i: number) => {
                  return x.text;
                })
                .join(", ");
            }}
            {...selectProperties}
          >
            {(sortOptions(props.options) ?? []).map(
              (x: { key: any; text: string; disabled?: boolean }, i: number) => (
                <MenuItem disabled={x.disabled} key={i} value={x.key}>
                  <ListItemIcon>
                    <Checkbox checked={props.defaultValue?.includes(x.key)} />
                  </ListItemIcon>
                  <ListItemText primary={x.text}>{x.text}</ListItemText>
                </MenuItem>
              )
            )}
          </Select>
        )}
        {helperText !== undefined && (
          <FormHelperText error={errored}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

const BetterSelect = (props: IBetterSelectProps) => {
  const [rerender, setRerender] = useState<boolean>(false);

  const doRerender = () => {
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 50);
  };

  useEffect(() => {
    if (props.defaultValue !== undefined && props.defaultValue !== null) {
      doRerender();
    }
  }, [props.defaultValue, props?.options]);

  return rerender ? (
    <BetterSelectInner {...props} onChange={(e) => {}} />
  ) : (
    <BetterSelectInner {...props} />
  );
};

export default BetterSelect;
