import { combineReducers } from "redux";
import { genericReducer } from "./Generic/GenericReducer";
import { GenericState } from "./Generic/GenericState";

const rootReducer = combineReducers({
  generic: genericReducer,
});

export interface GlobalState {
  generic: GenericState;
}

export default rootReducer;
