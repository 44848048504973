import { FormControl } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { decodeDateLocale } from "../../../Utils/Decoder";
import { IMultiFormField } from "../MultiForm";
import { dateFromUTC0_ISO, dateToUTC0_ISO } from "./StrongDatePicker";

export interface IStrongDateTimePickerProps extends IMultiFormField {
  onChange: (value: string) => void;
}

const StrongDateTimePicker = (props: IStrongDateTimePickerProps) => {
  const [value, setValue] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (props.defaultValue) {
      setValue(dayjs(dateFromUTC0_ISO(props.defaultValue)));
    }
  }, []);

  useEffect(() => {
    if (props.currentValue) {
      setValue(dayjs(dateFromUTC0_ISO(props.currentValue)));
    }
  }, [props.currentValue]);

  useEffect(() => {
    if (value !== null) {
      let output = dateToUTC0_ISO(value.toDate());
      if (output) {
        props.onChange(output);
      }
    }
  }, [value]);

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue?.isValid()) {
      setValue(newValue);
    }
  };

  const isDateDisabled = (date: Dayjs) => {
    if(props.disabledDates){
      return props.disabledDates.includes(date.format("YYYY-MM-DD"));
    } else {
      return false;
    }
  }; 

  return (
    <div>
      <FormControl fullWidth size={props.size ?? "small"}>
        <LocalizationProvider
          adapterLocale={decodeDateLocale()}
          dateAdapter={AdapterDayjs}
        >
          <DateTimePicker
            {...props}
            disabled={props.disabled}
            label={props.placeholder}
            value={dayjs(dateFromUTC0_ISO(props.defaultValue))}
            onChange={handleChange}
            minDate={props?.minDate as any}
            maxDate={props?.maxDate as any}
            shouldDisableDate={isDateDisabled}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

export default StrongDateTimePicker;
