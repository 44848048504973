export const JSONPrint = (json: any) => {
  if (json) {
    return (
      <div
        style={{
          whiteSpace: "pre",
          overflowY: "auto",
          maxHeight: "10em",
          border: "1px solid rgba(0,0,0,0.1)",
          background: "white",
        }}
      >
        {JSON.stringify(json, null, 4)}
      </div>
    );
  }
  return <div>No data</div>;
};

export const decodeHumanFileSize = (fileSizeInBytes: number) => {
  var i = -1;
  var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const decodeDateLocale = () => {
  let language = "";
  switch (localStorage.getItem("language")) {
    case "ita":
      language = "it";
      break;

    default:
      language = "it";
      break;
  }

  return language;
};
