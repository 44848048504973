import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IMultiFormField } from "../MultiForm";
import { FormHelperText } from "@mui/material";

export interface IBetterSelectMultipleProps extends IMultiFormField {
  onChange: (value: string) => void;
  loading?: boolean | false;
}

const BetterSelectMultiple = (props: IBetterSelectMultipleProps) => {
  const [selected, setSelected] = useState<any>([]);
  const isAllSelected = props.options && props.options.length > 0 && selected?.length === props.options.length;
  let helperText = props.errorText ? props.errorText : props.hint;
  let errored = props.errorText !== undefined && props.errorText !== "";

  // Set max height of the select window
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  // Select propeties
  let selectProperties: any = [];

  // Basic propeties
  selectProperties = {
    ...props,
    error: errored,
    MenuProps: MenuProps,
  };

  // Set inital value for the select
  if (props.currentValue !== undefined && props.currentValue !== null) {
    selectProperties["value"] = props.currentValue;
  }

  // clean errors on custom attributes
  delete selectProperties.errorText;
  delete selectProperties.customContainerClass;

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (props.options && value[value.length - 1] === "all") {
      setSelected(selected?.length === props.options.length ? [] : props.options.map((x: { key: any; text: string; disabled?: boolean}) => { return x.key }));
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    if(props.onChange){
      props.onChange(selected);
    }

  }, [selected]);

  return (
    <FormControl
      className="better-select-input"
      fullWidth
      disabled={props.disabled || props.loading}
      required={props.required}
      error={errored}
      placeholder=""
      size={props.size ?? "small"}
      variant={props.variant ? props.variant : "outlined"}
      sx={{ minWidth: 200 }}
    >
      <Select
        {...selectProperties}
        multiple
        value={selected || []}
        onChange={handleChange}
        renderValue={(selected: any) => {
          return (props.options ?? [])
            ?.filter((x: { key: string | number | boolean; text: string }) => {
              return selected.indexOf(x.key) > -1;
            })
            .map((x: { key: string | number | boolean; text: string }, i: number) => {
              return x.text;
            })
            .join(", ");
        }}
      >
        {props.options && (
          <MenuItem
            value="all"
          >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected?.length > 0 && selected.length < props.options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={"Select all"} />
          </MenuItem>
        )}
        {(props.options ?? []).map((x: { key: any; text: string; disabled?: boolean}, i: number) => {
          return (
            <MenuItem key={i} value={x.key}>
              <ListItemIcon>
                <Checkbox checked={selected?.includes(x.key)} />
              </ListItemIcon>
              <ListItemText primary={x.text} />
            </MenuItem>
          )
        })}
      </Select>
      {helperText !== undefined && (
        <FormHelperText error={errored}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default BetterSelectMultiple;
